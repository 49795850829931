<template>
  <b-modal
    id="form-dialogue"
    :visible="shallShowDialogueFormModal"
    :title="`${$t('Form')} ${$t('Dialogue')}`"
    footer-class="d-flex justify-content-between"
    size="xl"
    centered
    no-close-on-backdrop
    no-close-on-esc
    @change="(val) => $emit('update:shall-show-dialogue-form-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ `${$t(typeDialogueForm)} ${$t('Dialogue')}` }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="(busy === false) ? $emit('update:shall-show-dialogue-form-modal', false) : false"
        />
        <!-- <feather-icon
          icon="Maximize2Icon"
          class="ml-1 cursor-pointer"
        /> -->
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          v-if="(typeDialogueForm !== 'View')"
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          right
          :disabled="busy"
          @click="onSubmit"
        >
          {{ $t('Save Data') }}
        </b-button>
      </div>

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <div>
        <b-form
          ref="refForm"
          class="position-relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <div>
            <!-- Header -->
            <b-card style="margin-bottom: 0.6rem;">
              <div>
                <b-row>
                  <!-- Field: Name -->
                  <b-col cols="4">
                    <b-form-group
                      v-if="(typeDialogueForm === 'Add' || typeDialogueForm === 'Edit')"
                      :label="$t('Name')"
                      label-for="dialogue-name"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Name')"
                        rules="required"
                      >
                        <b-form-input
                          id="dialogue-name"
                          v-model="dialogueData.name"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                          :placeholder="$t('Name')"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Name')"
                      label-for="dialogue-name"
                    >
                      <span class="form-info-box">{{ dialogueData.name }}</span>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Context -->
                  <b-col cols="4">
                    <b-form-group
                      v-if="(typeDialogueForm === 'Add' || typeDialogueForm === 'Edit')"
                      :label="`${$t('Context')}`"
                      label-for="dialogue-context"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Context')"
                      >
                        <b-form-tags
                          v-model="dialogueData.context"
                          input-id="tags-basic"
                          remove-on-delete
                          :add-button-text="$t('Add')"
                          :placeholder="$t('Context')"
                        />
                        <small>ป้อนบริบทข้อความที่ต้องการให้ตอบกลับ</small>
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Context')"
                      label-for="dialogue-context"
                    >
                      <span class="form-info-box">{{ dialogueData.context }}</span>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Status -->
                  <b-col cols="4">
                    <b-form-group
                      v-if="(typeDialogueForm === 'Add' || typeDialogueForm === 'Edit')"
                      :label="$t('Status')"
                      label-for="dialogue-status"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Status')"
                        rules="required"
                      >
                        <b-form-radio
                          v-model="dialogueData.status"
                          name="dialogue-status"
                          value="active"
                          class="custom-control-success cursor-pointer"
                          inline
                        >
                          <span class="cursor-pointer">{{ $t('Active') }}</span>
                        </b-form-radio>
                        <b-form-radio
                          v-model="dialogueData.status"
                          name="dialogue-status"
                          value="inactive"
                          class="custom-control-danger cursor-pointer"
                          inline
                        >
                          <span class="cursor-pointer">{{ $t('Inactive') }}</span>
                        </b-form-radio>
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Status')"
                      label-for="dialogue-status"
                    >
                      <span class="form-info-box">
                        <b-badge
                          pill
                          :variant="`light-${isStatusVariant(dialogueData.status)}`"
                          class="text-capitalize"
                        >
                          {{ $t(textFirstUpper(dialogueData.status)) }}
                        </b-badge>
                      </span>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-card>
            <!-- Body -->
            <div style="margin-left: 1rem; margin-right: 1rem; margin-bottom: 0.6rem;">
              <b-row>
                <!-- Left -->
                <b-col
                  cols="6"
                  class="m-0 p-0"
                >
                  <b-card
                    class="m-0 p-0"
                  >
                    <!-- Left Top Button > Prev, Add, Remove, Edit -->
                    <b-row>
                      <b-col>
                        <b-button
                          v-if="(parseInt(indexLeft) > 0)"
                          size="sm"
                          variant="primary"
                          style="margin-bottom: 0.4rem; float: left;"
                          @click="onPrevious"
                        >
                          {{ $t('Previous') }}
                        </b-button>
                        <b-button
                          v-if="Object.keys(leftMessage).length === 0"
                          variant="outline-primary"
                          style="margin-bottom: 0.4rem; float: right;"
                          size="sm"
                          @click="addLeftMessage"
                        >
                          <feather-icon icon="PlusCircleIcon" />
                          {{ `${$t('Add')}` }}
                        </b-button>
                        <b-button
                          v-if="Object.keys(leftMessage).length > 0"
                          variant="outline-danger"
                          style="margin-left: 0.4rem; margin-bottom: 0.4rem; float: right;"
                          size="sm"
                          @click="removeLeftMessage"
                        >
                          <feather-icon icon="MinusCircleIcon" />
                          {{ `${$t('Remove')}` }}
                        </b-button>
                        <b-button
                          v-if="Object.keys(leftMessage).length > 0"
                          variant="outline-info"
                          style="margin-bottom: 0.4rem; float: right;"
                          size="sm"
                          @click="editLeftMessage"
                        >
                          <feather-icon icon="EditIcon" />
                          {{ `${$t('Edit')}` }}
                        </b-button>
                      </b-col>
                    </b-row>
                    <!-- Left Message -->
                    <b-row>
                      <b-col>
                        <line-chat-message
                          v-if="leftMessage !== null"
                          :data="leftMessage"
                          @action="onActionLeftMessage"
                        />
                      </b-col>
                    </b-row>
                    <!-- Left Action > imagemap -->
                    <b-row
                      v-if="leftAction.imagemap.length > 0"
                      class="mt-1"
                    >
                      <b-col>
                        <div>
                          <b-tabs v-model="tabLeft">
                            <b-tab
                              v-for="(action, index) in leftAction.imagemap"
                              :key="index"
                              :title="`${$t('Area')} ${index+1}`"
                            >
                              <div
                                style="border-radius: 0.8rem; border: 1px solid #eeeeee; padding: 12px 12px 12px 12px; margin-bottom: 0.5rem;"
                              >
                                <b-form-group
                                  v-if="action.area"
                                  :label="$t('Area')"
                                >
                                  <div
                                    style="border: 1px solid #eeeeee; padding: 12px 12px 12px 12px; margin-bottom: 0.5rem;"
                                  >
                                    <b-row>
                                      <b-col
                                        v-for="(value, key) in action.area"
                                        :key="key"
                                      >
                                        <span>{{ key }}</span>
                                        <b-form-input
                                          :value="value"
                                          style="margin-top: 5px;"
                                          readonly
                                        />
                                      </b-col>
                                    </b-row>
                                  </div>
                                </b-form-group>
                                <div style="padding: 0 1rem 0 1rem;">
                                  <span style="font-size: 18px; font-weight: 500;">
                                    {{ $t(textFirstUpper(action.type)) }}
                                  </span>
                                </div>
                                <div style="padding: 0.4rem 1rem 0.4rem 1rem;">
                                  <a
                                    v-if="action.linkUri && action.type === 'uri'"
                                    :href="action.linkUri"
                                    target="_blank"
                                  >
                                    {{ action.linkUri }}
                                  </a>
                                </div>
                                <div style="padding: 0rem 1rem 0.4rem 1rem;">
                                  <b-button
                                    v-if="action.text && action.type === 'message'"
                                    size="md"
                                    variant="warning"
                                    @click="onMessage(action, 'left')"
                                  >
                                    {{ action.text }}
                                  </b-button>
                                </div>
                                <div style="padding: 0rem 1rem 0.4rem 1rem;">
                                  <b-button
                                    v-if="action.type === 'postback'"
                                    size="md"
                                    variant="info"
                                    @click="onPostback(action, 'left')"
                                  >
                                    {{ action.data }}
                                  </b-button>
                                </div>
                              </div>
                            </b-tab>
                          </b-tabs>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <!-- Right -->
                <b-col
                  ref="refActionCard"
                  cols="6"
                  class="m-0 p-0"
                >
                  <b-card
                    class="m-0 ml-1 p-1"
                    no-body
                  >
                    <!-- Right Action > Top Button > Add, Remove -->
                    <b-row>
                      <b-col cols="6">
                        <!-- Right Action -->
                        <div
                          v-if="rightAction.type !== ''"
                          style="padding: 0 1rem 0 1rem;"
                        >
                          <span style="font-size: 12px;">
                            {{ rightAction.type }}
                          </span>
                        </div>
                        <div
                          v-if="rightAction.title !== ''"
                          style="padding: 0 1rem 0 1rem;"
                        >
                          <span style="font-size: 18px; font-weight: 500;">
                            {{ rightAction.title }}
                          </span>
                        </div>
                        <div
                          v-if="rightAction.urlLink !== ''"
                          style="padding: 0.4rem 1rem 0.4rem 1rem;"
                        >
                          <a
                            :href="rightAction.urlLink"
                            target="_blank"
                          >
                            {{ rightAction.urlLink }}
                          </a>
                        </div>
                        <div
                          v-if="rightAction.text !== ''"
                          style="padding: 0rem 1rem 0.4rem 1rem;"
                        >
                          <b-badge
                            variant="warning"
                            style="padding: 10px;"
                          >
                            {{ rightAction.text }}
                          </b-badge>
                        </div>
                        <div
                          v-if="rightAction.data !== ''"
                          style="padding: 0rem 1rem 0.4rem 1rem;"
                        >
                          <b-badge
                            variant="light-success"
                            style="padding: 10px;"
                          >
                            {{ rightAction.data }}
                          </b-badge>
                        </div>
                      </b-col>
                      <b-col cols="6">
                        <b-button
                          v-if="Object.keys(rightMessage).length === 0 && rightAction.type === 'postback'"
                          variant="outline-secondary"
                          style="margin-left: 0.4rem; margin-bottom: 0.4rem; float: right;"
                          size="sm"
                          @click="selectRightMessage"
                        >
                          <feather-icon icon="CircleIcon" />
                          {{ `${$t('Select')}` }}
                        </b-button>
                        <b-button
                          v-if="Object.keys(rightMessage).length === 0 && rightAction.type === 'postback'"
                          variant="outline-primary"
                          style="margin-bottom: 0.4rem; float: right;"
                          size="sm"
                          @click="addRightMessage"
                        >
                          <feather-icon icon="PlusCircleIcon" />
                          {{ `${$t('Add')}` }}
                        </b-button>
                        <b-button
                          v-if="Object.keys(rightMessage).length > 0 && rightAction.type === 'postback'"
                          variant="outline-danger"
                          style="margin-left: 0.4rem; margin-bottom: 0.4rem; float: right;"
                          size="sm"
                          @click="removeRightMessage"
                        >
                          <feather-icon icon="MinusCircleIcon" />
                          {{ `${$t('Remove')}` }}
                        </b-button>
                        <b-button
                          v-if="Object.keys(rightMessage).length > 0 && rightAction.type === 'postback'"
                          variant="outline-info"
                          style="margin-bottom: 0.4rem; float: right;"
                          size="sm"
                          @click="editRightMessage"
                        >
                          <feather-icon icon="EditIcon" />
                          {{ `${$t('Edit')}` }}
                        </b-button>
                        <b-button
                          v-if="Object.keys(rightMessage).length === 0 && rightAction.type === 'message'"
                          variant="outline-warning"
                          style="margin-bottom: 0.4rem; float: right;"
                          size="sm"
                          @click="addRightMessage"
                        >
                          <feather-icon icon="PlusCircleIcon" />
                          {{ `${$t('Add')}` }}
                        </b-button>
                        <b-button
                          v-if="Object.keys(rightMessage).length > 0 && rightAction.type === 'message'"
                          variant="outline-danger"
                          style="margin-left: 0.4rem; margin-bottom: 0.4rem; float: right;"
                          size="sm"
                          @click="removeRightMessage"
                        >
                          <feather-icon icon="MinusCircleIcon" />
                          {{ `${$t('Remove')}` }}
                        </b-button>
                        <b-button
                          v-if="Object.keys(rightMessage).length > 0 && rightAction.type === 'message'"
                          variant="outline-info"
                          style="margin-bottom: 0.4rem; float: right;"
                          size="sm"
                          @click="editRightMessage"
                        >
                          <feather-icon icon="EditIcon" />
                          {{ `${$t('Edit')}` }}
                        </b-button>
                      </b-col>
                    </b-row>
                    <!-- Right Message -->
                    <b-row>
                      <b-col>
                        <line-chat-message
                          v-if="rightMessage !== null"
                          :data="rightMessage"
                          @action="onActionRightMessage"
                        />
                      </b-col>
                    </b-row>
                    <!-- Right Action > imagemap -->
                    <b-row
                      v-if="rightAction.imagemap.length > 0"
                      class="mt-1"
                    >
                      <b-col>
                        <div>
                          <b-tabs v-model="tabRight">
                            <b-tab
                              v-for="(action, index) in rightAction.imagemap"
                              :key="index"
                              :title="`${$t('Area')} ${index+1}`"
                            >
                              <div
                                style="border-radius: 0.8rem; border: 1px solid #eeeeee; padding: 12px 12px 12px 12px; margin-bottom: 0.5rem;"
                              >
                                <b-form-group
                                  v-if="action.area"
                                  :label="$t('Area')"
                                >
                                  <div
                                    style="border: 1px solid #eeeeee; padding: 12px 12px 12px 12px; margin-bottom: 0.5rem;"
                                  >
                                    <b-row>
                                      <b-col
                                        v-for="(value, key) in action.area"
                                        :key="key"
                                      >
                                        <span>{{ key }}</span>
                                        <b-form-input
                                          :value="value"
                                          style="margin-top: 5px;"
                                        />
                                      </b-col>
                                    </b-row>
                                  </div>
                                </b-form-group>
                                <div style="padding: 0 1rem 0 1rem;">
                                  <span style="font-size: 18px; font-weight: 500;">
                                    {{ $t(textFirstUpper(action.type)) }}
                                  </span>
                                </div>
                                <div style="padding: 0.4rem 1rem 0.4rem 1rem;">
                                  <a
                                    v-if="action.linkUri && action.type === 'uri'"
                                    :href="action.linkUri"
                                    target="_blank"
                                  >
                                    {{ action.linkUri }}
                                  </a>
                                </div>
                                <div style="padding: 0rem 1rem 0.4rem 1rem;">
                                  <b-button
                                    v-if="action.text && action.type === 'message'"
                                    size="md"
                                    variant="warning"
                                    @click="onMessage(action, 'right')"
                                  >
                                    {{ action.text }}
                                  </b-button>
                                </div>
                                <div style="padding: 0rem 1rem 0.4rem 1rem;">
                                  <b-button
                                    v-if="action.type === 'postback'"
                                    size="md"
                                    variant="info"
                                    @click="onPostback(action, 'right')"
                                  >
                                    {{ action.data }}
                                  </b-button>
                                </div>
                              </div>
                            </b-tab>
                          </b-tabs>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </div>
          </div>

        </b-form>

      </div>

    </validation-observer>

    <dialogue-message-form
      v-model="shallShowDialogueMessageFormModal"
      :dialogue-message-data="dialogueMessageData"
      :chatmessage-type="chatmessageType"
      :type-dialogue-message-form="typeDialogueMessageForm"
      :action-dialogue-message-form="actionDialogueMessageForm"
      @update-dialogue-message-form="updateDialogueMessageForm"
      @discard-dialogue-message-form="discardDialogueMessageForm"
    />

    <dialogue-message-select
      v-model="showDialogueMessageSelectModal"
      :title="`${$t('Select')} ${$t('Dialogue Message')}`"
      :dialogue-message-data="dialogueMessageSelectData"
      @update-dialogue-message-select="updateDialogueMessageSelect"
      @discard-dialogue-message-select="discardDialogueMessageSelect"
    />

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton, BForm,
  BFormGroup, BFormInput,
  BRow,
  BCol,
  BCard,
  BOverlay,
  BProgress,
  BCardText,
  BFormTags,
  BFormRadio,
  BBadge,
  BTab,
  BTabs,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import LineChatMessage from '@core/components/line-chat-message/LineChatMessage.vue'
import DialogueMessageForm from './DialogueMessageForm.vue'
import DialogueMessageSelect from './DialogueMessageSelect.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    DialogueMessageForm,
    LineChatMessage,
    DialogueMessageSelect,

    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BProgress,
    BCardText,
    BFormTags,
    BFormRadio,
    BBadge,
    BTab,
    BTabs,

    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
  },
  model: {
    prop: 'shallShowDialogueFormModal',
    event: 'update:shall-show-dialogue-form-modal',
  },
  props: {
    shallShowDialogueFormModal: {
      type: Boolean,
      required: true,
    },
    typeDialogueForm: {
      type: String,
      default: 'Add',
    },
    dialogueData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      skin: store.state.appConfig.layout.skin,
      dialogueMessageData: {},
      dialogueMessageDataEdit: {},
      chatmessageType: [],
      shallShowDialogueMessageFormModal: false,
      typeDialogueMessageForm: '',
      actionDialogueMessageForm: '',

      lineChatMessage: [],
      selectAction: {},
      indexMessage: 0,
      indexPrev: [],
      indexLeft: 0,
      indexRight: 0,
      tabPrev: 0,
      tabLeft: 0,
      tabRight: 0,
      leftMessageData: {},
      leftMessage: {},
      leftAction: {
        imagemap: [],
      },
      rightMessageData: {},
      rightMessage: {},
      rightAction: {
        type: '',
        title: '',
        text: '',
        urlLink: '',
        data: '',
        imagemap: [],
      },
      doSave: false,
      startName: '',
      startContext: [],
      showDialogueMessageSelectModal: false,
      dialogueMessageSelectData: [],
    }
  },
  computed: {
    lineOa() {
      const value = store.state.lineapi.lineOa
      if (value === '' || value === undefined) {
        return ''
      }
      return JSON.parse(value)
    },
    lineOaId() {
      const value = this.lineOa.id
      if (value === '' || value === undefined) {
        return ''
      }
      return value
    },
  },
  watch: {
    shallShowDialogueFormModal() {
      if (this.shallShowDialogueFormModal === true) {
        // first open modal
        this.indexLeft = 0
        this.indexRight = 0

        this.lineChatMessage = []
        const dialogueMessage = this.dialogueData.dialogue_message
        if (dialogueMessage !== undefined) {
          this.lineChatMessage = dialogueMessage
        }

        if (this.typeDialogueForm === 'Add') {
          this.startName = ''
          this.startContext = []
        } else if (this.typeDialogueForm === 'Edit') {
          this.startName = this.dialogueData.name
          this.startContext = this.dialogueData.context
        }

        this.doSave = false

        if (this.lineChatMessage.length > 0) {
          this.doLeft()
          this.rightMessageData = {}
          this.rightMessage = {}
          this.rightAction = {
            type: '',
            title: '',
            text: '',
            urlLink: '',
            data: '',
            imagemap: [],
          }
        } else {
          this.leftMessage = {}
          this.leftMessageData = {}
          this.leftAction = {
            imagemap: [],
          }
          this.rightMessageData = {}
          this.rightMessage = {}
          this.rightAction = {
            type: '',
            title: '',
            text: '',
            urlLink: '',
            data: '',
            imagemap: [],
          }
        }
      }
    },
    // eslint-disable-next-line func-names
    'dialogueData.name': function (newValue) {
      if (newValue !== this.startName) {
        this.doSave = true
      }
    },
    // eslint-disable-next-line func-names
    'dialogueData.context': function (newValue) {
      if (newValue.length !== this.startContext.length) {
        this.doSave = true
      }
    },
  },
  methods: {
    textFirstUpper(text) {
      return text.slice(0, 1).toUpperCase() + text.slice(1, text.length)
    },
    isStatusVariant(isstatus) {
      if (isstatus === 'active') return 'success'
      if (isstatus === 'inactive') return 'danger'
      return 'danger'
    },
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          if (this.lineChatMessage.length > 0) {
            this.processing = false
            this.busy = true
          } else {
            // not found
            const msg = this.$i18n.t('Please add chat message')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: msg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      if (this.typeDialogueForm === 'Add') {
        delete this.dialogueData.line_oa_id
        this.dialogueData.lineOaId = this.lineOaId
        this.dialogueData.dialogueMessage = this.lineChatMessage
        store.dispatch('dialogue-store/addDialogue', this.dialogueData)
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('update-dialogue-form')
            this.$emit('update:shall-show-dialogue-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeDialogueForm === 'Edit') {
        this.dialogueData.lineOaId = this.lineOaId
        delete this.dialogueData.dialogue_message
        this.dialogueData.dialogueMessage = this.lineChatMessage
        store.dispatch('dialogue-store/editDialogue', { id: this.dialogueData.id, data: this.dialogueData })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('update-dialogue-form')
            this.$emit('update:shall-show-dialogue-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      }
    },
    onDiscard() {
      if (this.doSave === true) {
        this.$swal({
          title: this.$i18n.t('Are you sure?'),
          text: this.$i18n.t('Do you want to save before leaving?'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('No save, to leave'),
          cancelButtonText: this.$i18n.t('Cancel'),
          customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value === true) {
            this.$emit('discard-dialogue-form')
            this.$emit('update:shall-show-dialogue-form-modal', false)
          }
        })
      } else {
        this.$emit('discard-dialogue-form')
        this.$emit('update:shall-show-dialogue-form-modal', false)
      }
    },
    addLeftMessage() {
      this.showOverlay = true
      store
        .dispatch('dialogue-store/getDefaultDialogueMessage', { language: this.$i18n.locale, lineOaId: this.lineOaId })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.dialogueMessageData = response.data
          this.chatmessageType = response.data.chatmessageType
          this.typeDialogueMessageForm = 'Add'
          this.actionDialogueMessageForm = 'left'
          this.shallShowDialogueMessageFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    addRightMessage() {
      this.showOverlay = true
      store
        .dispatch('dialogue-store/getDefaultDialogueMessage', { language: this.$i18n.locale, lineOaId: this.lineOaId })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.dialogueMessageData = response.data
          this.chatmessageType = response.data.chatmessageType
          this.typeDialogueMessageForm = 'Add'
          this.actionDialogueMessageForm = 'right'
          this.shallShowDialogueMessageFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    editLeftMessage() {
      this.showOverlay = true
      store
        .dispatch('dialogue-store/getDefaultDialogueMessage', { language: this.$i18n.locale, lineOaId: this.lineOaId })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.chatmessageType = response.data.chatmessageType

          const data = this.lineChatMessage.find(o => o.index === parseInt(this.indexLeft, 10))
          this.dialogueMessageData = data
          const messageData = data.message
          if (data.type === 'flex') {
            const { altText, contents } = messageData
            this.dialogueMessageData.altText = altText
            this.dialogueMessageData.source = contents
          } else {
            this.dialogueMessageData.source = messageData
          }
          this.dialogueMessageData.type = data.type
          this.dialogueMessageData.type_name = this.textFirstUpper(data.type)

          this.dialogueMessageDataEdit = {
            context: data.context,
            dialogue_id: data.dialogue_id,
            id: data.id,
            index: data.index,
            postback: data.postback,
            message: data.message,
            type: data.type,
          }
          this.typeDialogueMessageForm = 'Edit'
          this.actionDialogueMessageForm = 'left'
          this.shallShowDialogueMessageFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    editRightMessage() {
      this.showOverlay = true
      store
        .dispatch('dialogue-store/getDefaultDialogueMessage', { language: this.$i18n.locale, lineOaId: this.lineOaId })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.chatmessageType = response.data.chatmessageType

          const data = this.lineChatMessage.find(o => o.index === parseInt(this.indexRight, 10))
          this.dialogueMessageData = data
          const messageData = data.message
          if (data.type === 'flex') {
            const { altText, contents } = messageData
            this.dialogueMessageData.altText = altText
            this.dialogueMessageData.source = contents
          } else {
            this.dialogueMessageData.source = messageData
          }
          this.dialogueMessageData.type_name = this.textFirstUpper(data.type)

          this.dialogueMessageDataEdit = {
            context: data.context,
            dialogue_id: data.dialogue_id,
            id: data.id,
            index: data.index,
            postback: data.postback,
            message: data.message,
            type: data.type,
          }
          this.typeDialogueMessageForm = 'Edit'
          this.actionDialogueMessageForm = 'right'
          this.shallShowDialogueMessageFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    removeLeftMessage() {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, remove it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          const findIndex = this.lineChatMessage.findIndex(o => o.index === parseInt(this.indexLeft, 10))
          if (findIndex >= 0) {
            if (findIndex === 0) {
              this.$swal({
                title: this.$i18n.t('Are you sure?'),
                text: this.$i18n.t('All removal is required.'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$i18n.t('Yes, remove all'),
                cancelButtonText: this.$i18n.t('Cancel'),
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              }).then(rr => {
                if (rr.value === true) {
                  this.lineChatMessage = []
                  this.doSave = true
                  this.doLeft()
                }
              })
            } else {
              if (this.selectAction.data !== undefined) {
                this.selectAction.data = ''
              }
              this.lineChatMessage.splice(findIndex, 1)
              this.onPrevious()
            }
          }
        }
      })
    },
    removeRightMessage() {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, remove it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          const findIndex = this.lineChatMessage.findIndex(o => o.index === parseInt(this.indexRight, 10))
          if (findIndex >= 0) {
            if (this.selectAction.data !== undefined) {
              this.selectAction.data = ''
            }
            this.lineChatMessage.splice(findIndex, 1)
            this.rightMessage = {}
            this.rightAction = {
              type: '',
              title: '',
              text: '',
              urlLink: '',
              data: '',
              imagemap: [],
            }
            this.doSave = true
          }
        }
      })
    },
    updateDialogueMessageForm(update) {
      const { data, type, action } = update
      if (type === 'Add') {
        let index = 0
        if (this.lineChatMessage.length > 0) {
          this.lineChatMessage.forEach(item => {
            if (parseInt(item.index, 10) > index) {
              index = parseInt(item.index, 10)
            }
          })
          index += 1
        }
        const addData = data
        delete addData.altText
        delete addData.chatmessageType
        delete addData.textMessage
        addData.index = index
        addData.postback = `|d=0|&index=${addData.index}`

        if (this.selectAction.type === 'message') {
          addData.context = this.selectAction.text
        }

        this.lineChatMessage.push(addData)
        this.doSave = true

        if (action === 'left') {
          this.indexLeft = addData.index
          if (this.selectAction.type === 'postback') {
            if (this.selectAction.data !== undefined) {
              this.selectAction.data = addData.postback
            }
          }
          this.doLeft()
        } else if (action === 'right') {
          this.indexRight = addData.index
          if (this.selectAction.type === 'postback') {
            this.rightAction.data = addData.postback
            if (this.selectAction.data !== undefined) {
              this.selectAction.data = addData.postback
            }
          }
          this.doRight(this.selectAction)
        }
      } else if (type === 'Edit') {
        this.doSave = true
        if (action === 'left') {
          this.doLeft()
        } else if (action === 'right') {
          this.doRight(this.selectAction)
        }
      }
    },
    discardDialogueMessageForm(discard) {
      const { type, action } = discard
      if (type === 'Edit') {
        if (action === 'left') {
          const findIndex = this.lineChatMessage.findIndex(o => o.index === parseInt(this.indexLeft, 10))
          this.lineChatMessage[findIndex] = this.dialogueMessageDataEdit
          this.doLeft()
        } else if (action === 'right') {
          const findIndex = this.lineChatMessage.findIndex(o => o.index === parseInt(this.indexRight, 10))
          this.lineChatMessage[findIndex] = this.dialogueMessageDataEdit
          this.doRight(this.selectAction)
        }
      }
    },
    onActionLeftMessage(action) {
      this.selectAction = action
      this.doRight(action)
    },
    onActionRightMessage(action) {
      this.selectAction = action
      this.indexPrev.push(this.indexLeft)
      this.tabPrev = this.tabLeft
      this.indexLeft = this.indexRight
      this.doLeft()
      this.doRight(action)
    },
    onPrevious() {
      this.indexRight = this.indexLeft
      this.doRightByIndex(this.indexRight)
      this.indexLeft = this.indexPrev[this.indexPrev.length - 1]
      this.tabRight = this.tabPrev
      this.indexPrev.splice(-1)
      this.doLeft()
    },
    onMessage(action, from) {
      if (from === 'left') {
        this.selectAction = action
        this.doRight(action)
      } else if (from === 'right') {
        this.selectAction = action
        this.indexPrev.push(this.indexLeft)
        this.tabPrev = this.tabLeft
        this.indexLeft = this.indexRight
        this.doLeft()
        this.doRight(action)
      }
    },
    onPostback(action, from) {
      this.selectAction = action
      if (from === 'left') {
        this.doRight(action)
      } else if (from === 'right') {
        this.indexPrev.push(this.indexLeft)
        this.tabPrev = this.tabLeft
        this.indexLeft = this.indexRight
        this.doLeft()
        this.doRight(action)
      }
    },
    doLeft() {
      const leftData = this.lineChatMessage.find(o => o.index === parseInt(this.indexLeft, 10))
      if (leftData) {
        this.leftMessageData = leftData
        this.leftMessage = leftData.message
        if (this.leftMessage.type === 'imagemap') {
          const { actions } = this.leftMessage
          this.leftAction.imagemap = actions
          this.tabLeft = this.tabRight
          this.tabRight = 0
        } else {
          this.leftAction.imagemap = []
        }
      } else {
        this.leftMessage = {}
        this.leftMessageData = {}
        this.leftAction = {
          imagemap: [],
        }
        this.rightMessageData = {}
        this.rightMessage = {}
        this.rightAction = {
          type: '',
          title: '',
          text: '',
          urlLink: '',
          data: '',
          imagemap: [],
        }
      }
    },
    doRight(action) {
      this.rightMessage = {}
      this.rightAction = {
        type: '',
        title: '',
        text: '',
        urlLink: '',
        data: '',
        imagemap: [],
      }
      if (action !== null) {
        const { label } = action
        if (label) {
          this.rightAction.title = label
        }
        const { type } = action
        this.rightAction.type = type
        if (type === 'postback') {
          const { data } = action
          if (data) {
            this.rightAction.data = data
            const params = new URLSearchParams(data)
            const index = params.get('index')
            if (index) {
              const msg = this.lineChatMessage.find(o => o.index === parseInt(index, 10))
              if (msg) {
                this.indexRight = index
                this.rightMessageData = msg
                this.rightMessage = msg.message
                if (this.rightMessage.type === 'imagemap') {
                  const { actions } = this.rightMessage
                  this.rightAction.imagemap = actions
                  this.tabRight = 0
                } else {
                  this.rightAction.imagemap = []
                }
              }
            }
          }
        } else if (type === 'uri') {
          const { uri } = action
          this.rightAction.urlLink = uri
        } else if (type === 'message') {
          const { text } = action
          this.rightAction.text = text
          // console.log(text)
          if (text !== '') {
            const msg = this.lineChatMessage.find(o => o.context === text)
            if (msg) {
              this.indexRight = msg.index
              this.rightMessageData = msg
              this.rightMessage = msg.message
              if (this.rightMessage.type === 'imagemap') {
                const { actions } = this.rightMessage
                this.rightAction.imagemap = actions
                this.tabRight = 0
              } else {
                this.rightAction.imagemap = []
              }
            }
          }
        }
      }
      this.$refs.refActionCard.scrollIntoView()
    },
    doRightByIndex(index) {
      this.rightMessage = {}
      this.rightAction = {
        type: '',
        title: '',
        text: '',
        urlLink: '',
        data: '',
        imagemap: [],
      }
      const rightData = this.lineChatMessage.find(o => o.index === parseInt(index, 10))
      if (rightData) {
        this.rightMessageData = rightData
        this.rightMessage = rightData.message
        if (this.rightMessage.type === 'imagemap') {
          const { actions } = this.rightMessage
          this.rightAction.imagemap = actions
          this.tabRight = 0
        } else {
          this.rightAction.imagemap = []
        }
      }
      this.$refs.refActionCard.scrollIntoView()
    },
    updateDialogueMessageSelect(data) {
      this.$nextTick(() => {
        this.indexRight = data.select.index
        if (this.selectAction.type === 'postback') {
          this.rightAction.data = data.select.postback
          if (this.selectAction.data !== undefined) {
            this.selectAction.data = data.select.postback
          }
        }
        this.doRight(this.selectAction)
      })
    },
    discardDialogueMessageSelect() {
    },
    selectRightMessage() {
      this.dialogueMessageSelectData = this.lineChatMessage
      this.showDialogueMessageSelectModal = true
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    return {
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
.jsoneditor-poweredBy {
  display: none !important;
}
.jsoneditor {
  border: thin solid #d8d6de !important;
}
.jsoneditor-menu{
  background-color: #aaaaaa !important;
  border-bottom: 1px solid #aaaaaa !important;
}
</style>
