import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Dialogue
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t } from '@/@core/libs/i18n/utils'

export default function useDialogueList() {
  // Use toast
  const toast = useToast()

  const refDialogueListTable = ref(null)
  const lineOa = computed(() => store.state.lineapi.lineOa)

  const msgDialogueList = {
    errorfetchDialogue: t('Error fetching {module} list', t('Dialogue')),
    id: `${t('Name')} » ${t('ID')}`,
    message: `${t('Dialogue')} » (${t('Chat Message')} » ${t('First')} )`,
    actions: t('Actions'),
  }

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true, label: msgDialogueList.id },
    { key: 'message', sortable: false, label: msgDialogueList.message },
    { key: 'actions', label: msgDialogueList.actions },
  ]

  const filterList = JSON.parse(localStorage.getItem('filterDialogueList'))
  const perPage = ref((filterList != null && filterList.perPage != null) ? filterList.perPage : 10)
  const totalDialogues = ref(0)
  // const currentPage = ref((filterList != null && filterList.page != null) ? filterList.page : 1)
  const currentPage = ref(1)
  const perPageOptions = [2, 5, 10, 25, 50, 100]
  const searchQuery = ref((filterList != null && filterList.q != null) ? filterList.q : '')
  const sortBy = ref((filterList != null && filterList.sortBy != null) ? filterList.sortBy : 'id')
  const isSortDirDesc = ref((filterList != null && filterList.sortDesc != null) ? filterList.sortDesc : true)
  const locale = ref('th')

  const dataMeta = computed(() => {
    const localItemsCount = refDialogueListTable.value ? refDialogueListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDialogues.value,
    }
  })

  const refetchData = () => {
    if (refDialogueListTable.value !== null) {
      refDialogueListTable.value.refresh()
    }
  }

  watch([currentPage, perPage, searchQuery, locale], () => {
    refetchData()
  })

  const getListDialogue = (ctx, callback) => {
    const { id } = JSON.parse(lineOa.value)
    const params = {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      language: locale.value,
      lineOaId: id,
    }
    store
      .dispatch('dialogue-store/getListDialogue', params)
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalDialogues.value = total
        localStorage.setItem('filterDialogueList', JSON.stringify(params))
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgDialogueList.errorfetchDialogue,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  return {
    getListDialogue,
    tableColumns,
    perPage,
    currentPage,
    totalDialogues,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    locale,
    refDialogueListTable,
    filterList,

    refetchData,
  }
}
