import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getListDialogue(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_list_dialogue', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDialogue(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_dialogue', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addDialogue(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/add_dialogue', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editDialogue(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/edit_dialogue', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteDialogue(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/lineapi/delete_dialogue/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDefaultDialogue(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/get_default_dialogue', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDefaultDialogueMessage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/get_default_dialogue_message', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListDialogueSelect(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_list_dialogue_select', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListDialogueMessageSelect(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_list_dialogue_message_select', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
