<template>
  <b-modal
    id="form-dialogue-message"
    :visible="shallShowDialogueMessageFormModal"
    :title="`${$t('Form')} ${$t('Dialogue Message')}`"
    footer-class="d-flex justify-content-between"
    size="xl"
    centered
    no-close-on-backdrop
    @change="(val) => $emit('update:shall-show-dialogue-message-form-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ `${$t(typeDialogueMessageForm)} ${$t('Dialogue Message')}` }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="(busy === false) ? $emit('update:shall-show-dialogue-message-form-modal', false) : false"
        />
        <!-- <feather-icon
          icon="Maximize2Icon"
          class="ml-1 cursor-pointer"
        /> -->
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          v-if="(typeDialogueMessageForm !== 'View')"
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          right
          :disabled="busy"
          @click="onSubmit"
        >
          {{ `${$t(typeDialogueMessageForm)} ${$t('Dialogue Message')}` }}
        </b-button>
      </div>

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <div>
        <b-form
          ref="refForm"
          class="position-relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <div>
            <b-card style="margin-bottom: 0.6rem;">
              <div>
                <b-row>
                  <!-- Left -->
                  <b-col cols="6">

                    <b-row>
                      <!-- Field: Select Message Type -->
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          v-if="typeDialogueMessageForm === 'Add'"
                          :label="`${$t('Select Message Type')} (${$t('Dialogue Message')})`"
                          style="margin-bottom: 0.5rem;"
                        >
                          <b-button
                            v-for="cmType in chatmessageType"
                            :key="cmType.value"
                            style="margin-bottom: 0.5rem; margin-right: 0.8rem;"
                            size="sm"
                            :variant="(dialogueMessageData.type === cmType.value)? 'info' : 'outline-info'"
                            @click="onSelectChatMessageType(cmType.value)"
                          >
                            {{ cmType.name }}
                          </b-button>
                        </b-form-group>

                        <b-form-group
                          v-if="typeDialogueMessageForm === 'View' || typeDialogueMessageForm === 'Edit'"
                          :label="`${$t('Select Message Type')} (${$t('Dialogue Message')})`"
                          style="margin-bottom: 0.5rem;"
                        >
                          <b-button
                            v-model="dialogueMessageData.type"
                            style="margin-bottom: 0.5rem; margin-right: 0.8rem;"
                            size="sm"
                            variant="info"
                          >
                            {{ dialogueMessageData.type_name }}
                          </b-button>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row
                      v-if="(typeDialogueMessageForm === 'Add' || typeDialogueMessageForm === 'Edit')"
                    >
                      <!-- Field: AltText/Text -->
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          v-if="dialogueMessageData.type === 'flex' || dialogueMessageData.type === 'imagemap'"
                          :label="`${$t('Alternative Text')}`"
                          label-for="dialogue-message-dialogue-message-flex"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Alternative Text')"
                            rules="required"
                          >
                            <b-form-textarea
                              v-model="dialogueMessageData.altText"
                              rows="1"
                              :placeholder="$t('Alternative Text')"
                              @keyup="keyupAltText"
                            />
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>

                        <b-form-group
                          v-if="dialogueMessageData.type === 'text'"
                          :label="`${$t('Text Message')}`"
                          label-for="dialogue-message-dialogue-message-text"
                        >
                          <b-form-textarea
                            v-model="dialogueMessageData.textMessage"
                            rows="1"
                            :placeholder="$t('Text Message')"
                            @keyup="keyupTextMessage"
                          />
                        </b-form-group>

                        <b-form-group
                          v-if="dialogueMessageData.type === 'imagemap'"
                          :label="`${$t('Base Url')}`"
                          label-for="dialogue-message-dialogue-message-imagemap"
                        >
                          <b-form-input
                            v-model="dialogueMessageData.baseUrl"
                            :placeholder="$t('Base Url')"
                            @change="changeBaseUrl"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row
                      v-if="(typeDialogueMessageForm === 'Add' || typeDialogueMessageForm === 'Edit')"
                    >
                      <!-- Field: Description::AltText/Text/Imagemap -->
                      <b-col
                        cols="12"
                      >
                        <span v-if="dialogueMessageData.type === 'flex'">
                          ดูรายละเอียดวิธีปรับแต่ง Flex Message ตามต้องการด้วย JSON
                          <a
                            href="https://developers.line.biz/flex-simulator/"
                            target="_blank"
                          >
                            <feather-icon
                              icon="ExternalLinkIcon"
                              size="18"
                              style="margin-top: -4px;"
                            />
                            สร้าง Flex Message
                          </a>
                        </span>
                        <span v-if="dialogueMessageData.type === 'text'">
                          ป้อนข้อความที่ต้องการ JSON จะสร้างขึ้นมาอัตโนมัติ
                        </span>
                        <span v-if="dialogueMessageData.type === 'imagemap'">
                          - Copy Imagemap URL from Media Module<br>
                          - Image format: JPEG or PNG Max file size: 10 MB<br>
                          - Image width: 240px, 300px, 460px, 700px, 1040px
                        </span>
                      </b-col>
                    </b-row>

                    <b-row class="mt-1">
                      <!-- Field: Source JSON Message -->
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          v-if="typeDialogueMessageForm === 'Add'"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="`${$t('JSON Message')}`"
                            rules="required"
                          >
                            <b-row>
                              <b-col
                                cols="6"
                                class="d-flex align-items-center justify-content-start"
                              >
                                <span>{{ `${$t('JSON Message')} (${$t('Source')})` }}</span>
                              </b-col>
                              <b-col
                                cols="6"
                                class="d-flex align-items-center justify-content-end m-0 p-0"
                              >
                                <b-button
                                  size="sm"
                                  class="mr-1"
                                  style="margin-bottom: 0.5rem;"
                                  variant="danger"
                                  @click="onClear"
                                >
                                  {{ $t('Clear') }}
                                </b-button>
                                <b-button
                                  size="sm"
                                  class="mr-1"
                                  style="margin-bottom: 0.5rem;"
                                  variant="success"
                                  @click="onPaste"
                                >
                                  {{ $t('Paste') }}
                                </b-button>
                              </b-col>
                            </b-row>
                            <v-jsoneditor
                              v-model="dialogueMessageData.source"
                              :options="optionsSourceJson"
                              :plus="false"
                              height="300px"
                              @error="onErrorSourceJson"
                            />
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row
                      class="mt-1"
                    >
                      <!-- Field: Message JSON Message -->
                      <b-col
                        cols="12"
                      >
                        <b-row>
                          <b-col
                            cols="6"
                            class="d-flex align-items-center justify-content-start"
                          >
                            <span>{{ `${$t('JSON Message')} (${$t('Line Message')})` }}</span>
                          </b-col>
                          <b-col
                            cols="6"
                            class="d-flex align-items-center justify-content-end m-0 p-0"
                          >
                            <b-button
                              size="sm"
                              class="mr-1"
                              style="margin-bottom: 0.5rem;"
                              variant="warning"
                              @click="onCopy"
                            >
                              {{ $t('Copy') }}
                            </b-button>
                            <b-button
                              size="sm"
                              class="mr-1"
                              style="margin-bottom: 0.5rem;"
                              variant="secondary"
                              @click="toggleShowLineMessage"
                            >
                              {{ (isShowLineMessage === false)? $t('Show Line Message') : $t('Hide Line Message') }}
                            </b-button>
                          </b-col>
                        </b-row>
                        <v-jsoneditor
                          v-if="isShowLineMessage"
                          v-model="dialogueMessageData.message"
                          :options="optionsViewMessage"
                          :plus="false"
                          height="300px"
                          read-only
                        />
                      </b-col>
                    </b-row>

                  </b-col>
                  <!-- Right -->
                  <b-col cols="6">
                    <line-chat-message
                      :data="jsonMessage"
                      @action="onAction"
                    />
                    <!-- Action Data / Flex -->
                    <div
                      v-if="dialogueMessageData.type === 'flex' && actionData !== null"
                      class="mt-1"
                    >
                      <b-form-group
                        :label="$t('Type')"
                      >
                        <b-form-input
                          v-model="actionData.type"
                          readonly
                        />
                      </b-form-group>
                      <b-form-group
                        :label="$t('Label')"
                      >
                        <b-form-input
                          v-model="actionData.label"
                        />
                      </b-form-group>
                      <b-form-group
                        v-if="actionData.type === 'uri'"
                        :label="$t('URI')"
                      >
                        <b-form-input
                          v-model="actionData.uri"
                        />
                      </b-form-group>
                      <b-form-group
                        v-if="actionData.type === 'message' || actionData.type === 'postback'"
                        :label="$t('Text')"
                      >
                        <b-form-input
                          v-model="actionData.text"
                        />
                      </b-form-group>

                      <b-form-group
                        v-if="actionData.type === 'postback' || actionData.type === 'datetimepicker'"
                        :label="$t('Data')"
                      >
                        <b-form-input
                          v-model="actionData.data"
                        />
                      </b-form-group>
                      <b-form-group
                        v-if="actionData.type === 'datetimepicker'"
                        :label="$t('Mode')"
                      >
                        <b-form-input
                          v-model="actionData.mode"
                        />
                      </b-form-group>
                      <b-form-group
                        v-if="actionData.type === 'datetimepicker'"
                        :label="$t('Initial')"
                      >
                        <b-form-input
                          v-model="actionData.initial"
                        />
                      </b-form-group>
                      <b-form-group
                        v-if="actionData.type === 'datetimepicker'"
                        :label="$t('Max')"
                      >
                        <b-form-input
                          v-model="actionData.max"
                        />
                      </b-form-group>
                      <b-form-group
                        v-if="actionData.type === 'datetimepicker'"
                        :label="$t('Min')"
                      >
                        <b-form-input
                          v-model="actionData.min"
                        />
                      </b-form-group>
                    </div>
                    <!-- Action Data / Imagemap -->
                    <div
                      v-if="dialogueMessageData.type === 'imagemap' && actionData !== null"
                      class="mt-1"
                    >
                      <div>
                        <b-tabs>
                          <b-tab
                            v-for="(action, index) in actionData"
                            :key="index"
                            :title="`${$t('Area')} ${index+1}`"
                          >
                            <div
                              style="border-radius: 0.8rem; border: 1px solid #eeeeee; padding: 12px 12px 12px 12px; margin-bottom: 0.5rem;"
                            >
                              <b-form-group
                                v-if="action.area"
                                :label="$t('Area')"
                              >
                                <div
                                  style="border: 1px solid #eeeeee; padding: 12px 12px 12px 12px; margin-bottom: 0.5rem;"
                                >
                                  <b-row>
                                    <b-col
                                      v-for="(value, key) in action.area"
                                      :key="key"
                                    >
                                      <span>{{ key }}</span>
                                      <b-form-input
                                        :value="value"
                                        style="margin-top: 5px;"
                                        readonly
                                      />
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-form-group>
                              <b-form-group
                                v-if="action.type"
                                :label="$t('Type')"
                              >
                                <b-form-input
                                  v-model="action.type"
                                  readonly
                                />
                              </b-form-group>
                              <b-form-group
                                v-if="action.text !== undefined && action.type === 'message'"
                                :label="$t('Text')"
                              >
                                <b-form-input
                                  v-model="action.text"
                                />
                              </b-form-group>
                              <b-form-group
                                v-if="action.linkUri !== undefined && action.type === 'uri'"
                                :label="$t('Link Uri')"
                              >
                                <b-form-input
                                  v-model="action.linkUri"
                                />
                              </b-form-group>
                              <b-form-group
                                v-if="action.data !== undefined && action.type === 'postback'"
                                :label="$t('Data')"
                              >
                                <b-form-input
                                  v-model="action.data"
                                />
                              </b-form-group>
                            </div>
                          </b-tab>
                        </b-tabs>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </div>
        </b-form>
      </div>
    </validation-observer>

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton, BForm,
  BFormGroup, BFormInput,
  BRow,
  BCol,
  BCard,
  BOverlay,
  BProgress,
  BCardText,
  BFormTextarea,
  BTab,
  BTabs,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import VJsoneditor from 'v-jsoneditor'
import LineChatMessage from '@core/components/line-chat-message/LineChatMessage.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    LineChatMessage,

    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BProgress,
    BCardText,
    BFormTextarea,
    BTab,
    BTabs,

    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
    VJsoneditor,
  },
  model: {
    prop: 'shallShowDialogueMessageFormModal',
    event: 'update:shall-show-dialogue-message-form-modal',
  },
  props: {
    shallShowDialogueMessageFormModal: {
      type: Boolean,
      required: true,
    },
    typeDialogueMessageForm: {
      type: String,
      default: 'Add',
    },
    dialogueMessageData: {
      type: Object,
      default: () => {},
    },
    chatmessageType: {
      type: Array,
      default: () => [],
    },
    actionDialogueMessageForm: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      skin: store.state.appConfig.layout.skin,
      optionsSourceJson: {
        mode: 'code',
        mainMenuBar: true,
        enableSort: false,
        enableTransform: false,
        statusBar: false,
      },
      optionsViewMessage: {
        mode: 'code',
        mainMenuBar: false,
        enableSort: false,
        enableTransform: false,
        statusBar: false,
      },
      jsonMessage: {},
      actionData: null,
      isShowLineMessage: false,
    }
  },
  computed: {
    lineOa() {
      const value = store.state.lineapi.lineOa
      if (value === '' || value === undefined) {
        return ''
      }
      return JSON.parse(value)
    },
    lineOaId() {
      const value = this.lineOa.id
      if (value === '' || value === undefined) {
        return ''
      }
      return value
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'dialogueMessageData.source': function (newValue) {
      if (!Array.isArray(newValue)) {
        const { type } = newValue
        if (type === 'imagemap') {
          if (newValue.altText) {
            this.dialogueMessageData.altText = newValue.altText
          }
        }
        if (type === 'text') {
          if (newValue.text) {
            this.dialogueMessageData.textMessage = newValue.text
          }
        }
        if (type === 'flex') {
          if (newValue.altText) {
            this.dialogueMessageData.altText = newValue.altText
          }
          this.dialogueMessageData.source = this.dialogueMessageData.source.contents
        }
        if (type === 'bubble' || type === 'carousel' || type === 'flex') {
          this.dialogueMessageData.type = 'flex'
        } else if (type) {
          const found = this.chatmessageType.find(({ value }) => value === type)
          if (found) {
            this.dialogueMessageData.type = type
          } else {
            this.dialogueMessageData.type = 'flex'
          }
        } else {
          this.dialogueMessageData.type = 'flex'
        }

        if (this.dialogueMessageData.type === 'flex') {
          this.dialogueMessageData.message = {
            type: 'flex',
            altText: this.dialogueMessageData.altText,
            contents: this.dialogueMessageData.source,
          }
        } else if (this.dialogueMessageData.type === 'imagemap') {
          this.dialogueMessageData.message = this.dialogueMessageData.source
          this.dialogueMessageData.message.altText = this.dialogueMessageData.altText
        } else {
          this.dialogueMessageData.message = this.dialogueMessageData.source
        }
      } else {
        this.dialogueMessageData.source = ''
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$i18n.t('Invalid JSON Format'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      }
    },
    // eslint-disable-next-line func-names
    'dialogueMessageData.message': function (newValue) {
      if (newValue !== '' && newValue !== null) {
        this.actionData = null
        if (this.dialogueMessageData.type === 'flex') {
          this.dialogueMessageData.altText = newValue.altText
          if (this.dialogueMessageData.message.contents !== '') {
            this.jsonMessage = newValue
          } else {
            this.jsonMessage = {}
          }
        } else if (this.dialogueMessageData.type === 'imagemap') {
          this.dialogueMessageData.altText = newValue.altText
          this.jsonMessage = newValue
        } else {
          this.jsonMessage = newValue
        }
        if (this.dialogueMessageData.type === 'imagemap') {
          const { actions, baseUrl } = this.dialogueMessageData.message
          this.actionData = actions
          this.dialogueMessageData.baseUrl = baseUrl
        } else if (this.dialogueMessageData.type === 'text') {
          const { text } = this.dialogueMessageData.message
          if (text !== '') {
            this.dialogueMessageData.textMessage = text
          }
        }
      } else {
        this.jsonMessage = {}
      }
    },
    shallShowDialogueMessageFormModal() {
      if (this.shallShowDialogueMessageFormModal === true) {
        this.isShowLineMessage = false
        if (this.typeDialogueMessageForm === 'Edit') {
          this.isShowLineMessage = true
        }
      }
    },
  },
  methods: {
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      if (this.typeDialogueMessageForm === 'Add') {
        this.busy = false
        this.processing = false
        this.$emit('update-dialogue-message-form',
          {
            data: this.dialogueMessageData,
            type: this.typeDialogueMessageForm,
            action: this.actionDialogueMessageForm,
          })
        this.$emit('update:shall-show-dialogue-message-form-modal', false)
      } else if (this.typeDialogueMessageForm === 'Edit') {
        this.busy = false
        this.processing = false
        this.$emit('update-dialogue-message-form',
          {
            data: this.dialogueMessageData,
            type: this.typeDialogueMessageForm,
            action: this.actionDialogueMessageForm,
          })
        this.$emit('update:shall-show-dialogue-message-form-modal', false)
      }
    },
    onDiscard() {
      this.$emit('discard-dialogue-message-form', { type: this.typeDialogueMessageForm, action: this.actionDialogueMessageForm })
      this.$emit('update:shall-show-dialogue-message-form-modal', false)
    },
    onErrorSourceJson() {
      // console.log('error')
    },
    onSelectChatMessageType(type) {
      this.dialogueMessageData.type = type
      this.dialogueMessageData.source = ''
      this.dialogueMessageData.message = ''
      if (type === 'text' && this.dialogueMessageData.textMessage !== '') {
        this.keyupTextMessage()
      }
    },
    keyupAltText() {
      if (this.dialogueMessageData.type === 'flex') {
        this.dialogueMessageData.message = {
          type: 'flex',
          altText: this.dialogueMessageData.altText,
          contents: this.dialogueMessageData.source,
        }
      } else if (this.dialogueMessageData.type === 'imagemap') {
        this.dialogueMessageData.message.altText = this.dialogueMessageData.altText
      }
    },
    keyupTextMessage() {
      this.dialogueMessageData.source = {
        type: 'text',
        text: this.dialogueMessageData.textMessage,
      }
      this.dialogueMessageData.message = this.dialogueMessageData.source
    },
    changeBaseUrl() {
      if (this.dialogueMessageData.source !== '' && this.dialogueMessageData.source?.baseUrl) {
        this.dialogueMessageData.source.baseUrl = this.dialogueMessageData.baseUrl
        this.dialogueMessageData.message = this.dialogueMessageData.source
      }
    },
    onPaste() {
      navigator.clipboard
        .readText()
        .then(
          cliptext => {
            try {
              this.dialogueMessageData.source = JSON.parse(cliptext)
            } catch (e) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$i18n.t('Invalid JSON Format'),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
          },
          err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          },
        )
    },
    onClear() {
      this.dialogueMessageData.type = 'flex'
      this.dialogueMessageData.source = ''
      this.dialogueMessageData.message = ''
      this.dialogueMessageData.baseUrl = ''
      this.dialogueMessageData.textMessage = ''
      this.dialogueMessageData.altText = ''
      this.actionData = null
    },
    onCopy() {
      const copyText = (this.dialogueMessageData.message) ? JSON.stringify(this.dialogueMessageData.message) : ''
      navigator.clipboard.writeText(copyText).then(() => {
        const okMsg = this.$i18n.t('Copied to clipboard')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: okMsg,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      })
    },
    onAction(action) {
      this.actionData = action
    },
    toggleShowLineMessage() {
      this.isShowLineMessage = !this.isShowLineMessage
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    return {
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
.jsoneditor-poweredBy {
  display: none !important;
}
.jsoneditor {
  border: thin solid #d8d6de !important;
}
.jsoneditor-menu{
  background-color: #aaaaaa !important;
  border-bottom: 1px solid #aaaaaa !important;
}
</style>
